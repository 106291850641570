<template>
  <v-footer fixed clipped-left app light class="footer-color">
    <v-spacer></v-spacer>
    &copy; {{ year }} {{ websiteName }}
    <v-spacer></v-spacer>
    <v-hover v-slot="{ hover }" v-show="hasOnboarding">
      <v-btn
        depressed
        icon
        :color="hover ? 'info' : ''"
        @click="setOnboarding()"
      >
        <v-icon>mdi-information</v-icon>
      </v-btn>
    </v-hover>
    <v-hover v-slot="{ hover }">
      <v-btn
        depressed
        icon
        :color="hover ? 'error' : ''"
        @click="$store.dispatch('setErrorDialog', true)"
      >
        <v-icon>mdi-alert</v-icon>
      </v-btn>
    </v-hover>
  </v-footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {},
  data() {
    return {
      year: new Date().getFullYear(),
      websiteName: this.$config.websiteName,
    };
  },
  computed: {
    ...mapGetters({
      hasOnboarding: "hasOnboarding",
    }),
  },
  methods: {
    setOnboarding() {
      this.$store.dispatch("getOnboardingItems");
      this.$store.dispatch("setOnboarding", true);
    },
  },
};
</script>

<style scoped>
.footer-color {
  background-color: #f5f5f5 !important;
}
</style>